/* CSS Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  overflow-x: hidden;
}

html, body, #root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html::-webkit-scrollbar {
  width: 10px;
  background-color: #575757;
}
html::-webkit-scrollbar-track {
  border: none;
  background-color: #575757;
}
html::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #fff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html.locked {
  overflow: hidden;
}

body {
  overflow-x: hidden;
  font-family: "Open Sans";
}

a {
  text-decoration: none;
}